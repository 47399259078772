
.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  
  .wrapper {
    width: 100%; }
  
  #sidebar {
    min-width: 280px;
    max-width: 280px;
    /* height:100%; */
    min-height: 100%;
    background: #fff;
    color: #000;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 5; /* Stay at the top */
    left: 0;
  }

    .sidebar-section{
    height:100vh;

    }
  
    #sidebar .h6 {
      color: #fff; }
    #sidebar.active {
      margin-left: -300px; }
      #sidebar.active .custom-menu {
        margin-right: -98px; }
      #sidebar.active .btn.btn-primary:before {
        content: "\f054";
        font-family: "FontAwesome";
        left: 2px !important; }
      #sidebar.active .btn.btn-primary:after {
        display: none; }
    #sidebar h1 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 18px; }
      #sidebar h1 .logo {
        color: #fff;
        display: block;
        padding: 10px 30px;
        background: #962A30; }
    #sidebar ul.components {
      padding: 0; }
    #sidebar ul li {
      font-size: 14px; }
      #sidebar ul li > ul {
        margin-left: 10px; }
        #sidebar ul li > ul li {
          font-size: 14px; }
      #sidebar ul li a {
        padding: 12px 30px;
        display: block;
        color: #000;
        text-decoration: none;
        border-bottom: 1px solid rgba(24, 25, 27, 0.05); }
        #sidebar ul li a span.notif {
          position: relative; }
          #sidebar ul li a span.notif small {
            position: absolute;
            top: -3px;
            bottom: 0;
            right: -3px;
            width: 12px;
            height: 12px;
            content: '';
            background: red;
            border-radius: 50%;
            font-family: "Poppins", Arial, sans-serif;
            font-size: 8px; }
        #sidebar ul li a:hover {
          color: #fff!important;
          background: #962A30;
          border-bottom: 1px solid #962A30; }
      #sidebar ul li.active > a {
        background: transparent;
        color: #fff!important; }
        #sidebar ul li.active > a:hover {
          background: #962A30;
          border-bottom: 1px solid #962A30; }
    @media (max-width: 991.98px) {
      #sidebar {
        margin-left: -300px; }
        #sidebar.active {
          margin-left: 0; }
        #sidebar .custom-menu {
          margin-right: -60px !important;
          top: 10px !important; } }
    #sidebar .custom-menu {
      display: inline-block;
      position: absolute;
      top: 7px;
      right:0px;
      margin-right: -35px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      @media (prefers-reduced-motion: reduce) {
        #sidebar .custom-menu {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
      #sidebar .custom-menu .btn.btn-primary {
        background: transparent;
        border-color: transparent;
        position: relative;
        color: #fff;
        width: 30px;
        height: 30px; }
        #sidebar .custom-menu .btn.btn-primary:after, #sidebar .custom-menu .btn.btn-primary:before {
          position: absolute;
          top: 2px;
          left: 0;
          right: 0;
          bottom: 0;
          font-family: "FontAwesome";
          color: #000; }
        #sidebar .custom-menu .btn.btn-primary:after {
          content: "\f053";
          right: 2px; }
/*   
  .bg-wrap {
    width: 100%;
    position: relative;
    z-index: 0; } */
    /* .bg-wrap:after {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: #fff;
      opacity: .3; } */
    /* .bg-wrap .user-logo .img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 10px; }
    .bg-wrap .user-logo h3 {
      color: #fff;
      font-size: 18px; } */
  
  a[data-toggle="collapse"] {
    position: relative; }
  
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  
  @media (max-width: 991.98px) {
    #sidebarCollapse span {
      display: none; } }
  
  .content {
    width: 100%;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
  
    #content::-webkit-scrollbar {
      display: none;
    }
  
  .active
  {
    color:#000 !important;
  }
  
  
  