
.auth{
    background-color: #962A30;
    height: 100vh;
}


h3 { 
    width:100%; 
    text-align:center; 
    border-bottom: 1px solid #000; 
    line-height:0.1em; 
    margin:10px 0 20px; 
    font-size: 14px;
} 

h3 span { 
    background:#fff;
    padding:0 10px; 
}


.authentication .btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer
}

.btn-google {
    color: #545454;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 1px #ddd
}

