html {scroll-behavior: smooth;}
body{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    /* padding-top: 50px; */
    line-height: 1.5;
    background-color: #f8f9fa!important;
}

.wrapper{
    padding-top: 50px;
}

.main{
    margin-left: 300px; 
    padding: 0px 5px;
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
    color: #000;
}
h1, .h1 {font-size: 1.9em;}
h2, .h2 {font-size: 1.7em;}
h3, .h3 {font-size: 1.15em;}
h4, .h4 {font-size: 1.12em;}
h5, h6, .h5, .h6{font-size: 0.9em; }

p,li {color: #666; font-size: 0.8em;}
a {color: #444; text-decoration: none;  transition: 0.3s;}

.btn-primary{
    background-color:#962A30;
}

.text-primary{
    background-color:#962A30;
}

.btn-outline-primary{
    color:#962A30;
    border: 1px solid #962A30;
    font-size: 0.8em;
}

.btn-outline-primary:hover{
    background-color: #962A30;
}

::placeholder{
    font-size: 0.9em;
}

a.nav-drop-ref{
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

tr,th,td {
    font-size: 0.9em;
}

label{
    font-size: 0.9em;
}

option{
    font-size: 0.9em;
}

.widgets-box{
    height: 100%;
}

.widgets-text{
    font-size: 35px;
    font-weight: 600;
}